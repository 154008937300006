import * as Dialog from "@radix-ui/react-dialog";

import { X } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPix } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";

import CheckoutBody from "../CheckoutBody";
import PaymentBody from "../PaymentBody";
import PaymentConfirmed from "../PaymentConfirmed";

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
}

export default function CheckoutModal({ isOpen, setIsOpen }: ModalProps) {
  const [formStep, setFormStep] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [purchaseId, setPurchaseId] = useState("");
  const [dueDatePayment, setDueDatePayment] = useState<Date>();

  function handleStepForm() {
    switch (formStep) {
      case "paymentConfirmed":
        return (
          <PaymentConfirmed
            purchaseId={purchaseId}
            setIsOpen={setIsOpen}
            setFormStep={setFormStep}
          />
        );
      case "payment":
        return (
          <PaymentBody
            purchaseId={purchaseId}
            qrcode={qrcode}
            setFormStep={setFormStep}
            setIsOpen={setIsOpen}
            dueDatePayment={dueDatePayment}
          />
        );
      default:
        return (
          <CheckoutBody
            setFormStep={setFormStep}
            setQrcode={setQrcode}
            setPurchaseId={setPurchaseId}
            setDueDatePayment={setDueDatePayment}
          />
        );
    }
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="w-screen h-screen bg-black/80 data-[state=open]:animate-overlayShow fixed inset-0 z-40" />
        <Dialog.Content
          className={`fixed  bg-[#FFFFFF] border-2 border-darkText rounded-2xl w-[350px] md:w-[580px] lg:w-[770px] max-w-[90vw] max-h-[90vh] lg:max-h-[97vh] lg:scrollbarLG p-3 lg:p-7 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50 overflow-y-auto overflow-x-hidden data-[state=open]:animate-contentShow shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none`}
          onInteractOutside={(e) => {
            setFormStep("");
            setPurchaseId("");
            setQrcode("");
          }}
        >
          <Dialog.Close
            className="absolute right-3 top-4 text-zinc-400 rounded-lg hover:text-zinc-200 focus:outline-none focus:ring-2 focus:ring-violet-600 focus:ring-offset-2 focus:ring-offset-zinc-900"
            asChild
            onClick={() => setFormStep("")}
          >
            <X size={20} aria-label="Fechar" />
          </Dialog.Close>

          <Dialog.Title className="flex flex-row-reverse items-start justify-between mb-2 lg:mb-5 gap-3 rounded-t-lg">
            <div className="relative mr-7">
              <FontAwesomeIcon
                icon={faPix}
                className="bottom-[22px] right-5 text-[#3A3A3A] w-8 lg:w-12 h-8 lg:h-12"
              />
            </div>

            <div className="flex flex-col items-start justify-start">
              <h1 className="text-[#3A3A3A] text-base lg:text-3xl leading-tight font-bold">
                Concorrer ao Blend365 PIX
              </h1>
            </div>
          </Dialog.Title>

          {handleStepForm()}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
