import { ToasterProps } from "@/@types/AppTypes";
import * as animationData from "../assets/Animation - 1704392522638.json";
import Lottie from "react-lottie";
import { MapPin } from "@phosphor-icons/react";

export default function NewPurchaseToaster({
  name,
  document,
  price,
  location,
  selected_color,
}: ToasterProps) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className={`flex w-[350px] lg:w-[568px] items-center gap-1 lg:gap-[12px] px-3 lg:px-[15px] py-[2px] relative bg-hdgraybranco rounded-[16px] border-2 border-solid border-[#02a244] shadow-[0px_4px_27px_#00000040]`}
    >
      <div className="w-[50px] lg:w-[72px] h-[52px] lg:h-[75px]">
        <Lottie options={defaultOptions} />
      </div>
      <div className="flex items-center justify-between px-2 lg:px-[16px] py-1 lg:py-[8px] relative flex-1 grow rounded-[12px] border border-solid border-[#e6e6e6]">
        <div className="inline-flex items-center gap-[1px] lg:gap-[8px] relative flex-[0_0_auto]">
          <div className="relative w-fit [font-family:'Inter-Medium',Helvetica] font-medium text-[#454545] text-[9px] lg:text-[16px] tracking-[0] leading-[normal] whitespace-nowrap">
            {name}
          </div>
        </div>
        <div className="inline-flex items-center gap-1 lg:gap-[10px] px-2 lg:px-[12px] py-[4px] relative flex-[0_0_auto] bg-[#f42a375d] rounded-[500px]">
          <div className="relative w-fit mt-[-1.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#454545] text-[9px] lg:text-[15px] tracking-[0] leading-[normal] whitespace-nowrap">
            +{" "}
            {new Intl.NumberFormat("pt-br", {
              style: "currency",
              currency: "BRL",
            }).format(Number(price))}
          </div>
        </div>
      </div>
      <div className="inline-flex h-[44px] items-center justify-center gap-[10px] px-2 lg:px-[17px] py-1 lg:py-[10px] relative flex-[0_0_auto] bg-[#f42a37] rounded-[9px] border-2 border-solid border-[#f42a37]">
        <div className="relative w-fit [font-family:'Inter-SemiBold',Helvetica] font-semibold text-branco text-[10px] lg:text-[13px] tracking-[0] leading-[normal]">
          Visualizar
        </div>
      </div>
      <button className="flex w-[100px] lg:w-[130px] h-[24px] lg:h-[29px] items-center justify-center gap-[16px] px-[24px] py-[8px] absolute top-[-4px] left-[50px] lg:left-[110px] rounded-[100px] [background:linear-gradient(180deg,rgb(238,114,25)_0%,rgb(251,186,0)_100%)] all-[unset] box-border">
        <div className="relative w-fit mt-[-2.50px] mb-[-0.50px] ml-[-8.00px] mr-[-8.00px] [font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-[9px] lg:text-[13px] tracking-[0] leading-[normal]">
          NOVA APOSTA!
        </div>
      </button>
    </div>
  );
}
