import ResultCard from "./ResultCard";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store";
import { Instagram } from "lucide-react";
import {
  CaretDown,
  CaretUp,
  Check,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import * as Select from "@radix-ui/react-select";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { getResults } from "../lib/react-query/queryFunctions";
import { ResultSectionProps } from "@/@types/AppTypes";
import clsx from 'clsx';

let options = [
  {
    value: "day",
    description: "Últimos do dia",
  },
  {
    value: "week",
    description: "Últimos da semana",
  },
  {
    value: "month",
    description: "Últimos do mês",
  },
  {
    value: "hour",
    description: "Últimos da hora",
  },
];

export default function ResultsSection({
  results,
  priceOrder,
  value,
  setValue,
  setPriceOrder,
}: ResultSectionProps) {
  function handleOrderClick() {
    if (priceOrder === "asc") {
      setPriceOrder("desc");
    } else {
      setPriceOrder("asc");
    }
  }
  return (
    <section className="relative flex flex-col w-full h-fit items-center gap-1 py-10 bg-[#f42a375d]">
      <div className="flex flex-col lg:flex-row w-full h-fit items-center justify-between px-3 lg:px-8 lg:pl-20">
        <div className="flex flex-col items-center lg:items-start gap-3">
          <div className="flex flex-row items-center justify-center gap-3">
            <svg
              width="50"
              height="60"
              viewBox="0 0 50 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.87961 39.3612L22.5143 24.7265L7.87961 10.0918C3.65289 10.7265 0.390625 14.3485 0.390625 18.7493C0.390625 21.0152 1.23859 23.1283 2.73203 24.7265C1.23859 26.3247 0.390625 28.4379 0.390625 30.7038C0.390625 35.1045 3.65289 38.7266 7.87961 39.3612Z"
                fill="#363636"
              />
              <path
                d="M30.9772 0C28.7113 0 26.5982 0.847969 25 2.34141C23.4018 0.847969 21.2886 0 19.0227 0C14.6219 0 10.9999 3.37945 10.3651 7.60617L25 22.2409L39.6347 7.60617C39 3.37945 35.378 0 30.9772 0Z"
                fill="#363636"
              />
              <path
                d="M27.4857 24.7266L42.1204 39.3614C46.3472 38.7267 49.6094 35.1047 49.6094 30.7039C49.6094 28.438 48.7615 26.3248 47.268 24.7266C48.7615 23.1284 49.6094 21.0153 49.6094 18.7494C49.6094 14.3486 46.3472 10.7266 42.1204 10.0918L27.4857 24.7266Z"
                fill="#363636"
              />
              <path
                d="M10.9375 56.4841V59.9997C19.357 59.9997 25.4296 55.1343 26.5288 48.1115C27.8523 48.8912 29.3713 49.3356 30.9772 49.3356C35.378 49.3356 39 46.0734 39.6348 41.8466L25 27.2119L10.3652 41.8466C10.9999 46.0734 14.622 49.3356 19.0227 49.3356C20.4299 49.3356 21.7604 48.9749 22.9631 48.3692C21.6946 53.8709 16.2953 56.4841 10.9375 56.4841Z"
                fill="#363636"
              />
            </svg>

            <p className="text-[37px] leading-[37.26px] lg:text-[43px] lg:leading-[43px] font-bold text-[#363636]">
              Jackpots Realizados
            </p>
          </div>

          <div className="flex flex-row items-center justify-center gap-3 mt-3 lg:mt-0">
            <button
              className={`flex flex-row items-center justify-center px-4 py-2 gap-2 ${priceOrder === "asc" ? "bg-transparent" : "bg-[#f42a39]"
                } border border-[#f42a39] rounded-full`}
              onClick={handleOrderClick}
            >
              <CurrencyCircleDollar
                className={`w-5 h-5 ${priceOrder === "asc" ? "text-[#363636]" : "text-[#161E2C]"
                  }`}
                weight="fill"
              />
              <span
                className={`${priceOrder === "asc" ? "text-[#363636]" : "text-[#161E2C]"
                  } text-[15px] font-semibold tracking-tight`}
              >
                Maior valor
              </span>
            </button>

            <Select.Root value={value!} onValueChange={setValue}>
              <Select.Trigger
                className="inline-flex items-center justify-between rounded-full px-4 flex-grow h-10 gap-1 bg-transparent border border-[#f42a39] hover:border-[#f42a39]/80 focus:shadow-md"
                aria-label="Filtro Hora"
              >
                <Select.Value
                  placeholder="Escolha abaixo"
                  className="text-[16px] font-semibold tracking-tight"
                />
                <Select.Icon className="text-[#f42a39]">
                  <CaretDown />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content
                  className="overflow-hidden bg-tertiary border-gray-100 rounded-md shadow-lg shadow-[#f42a39] z-50"
                  position="popper"
                >
                  <Select.ScrollUpButton className="flex items-center justify-center h-6 bg-tertiary text-[#f42a39] cursor-default">
                    <CaretUp />
                  </Select.ScrollUpButton>
                  <Select.Viewport className="p-1 z-50">
                    {options.map((option) => (
                      <Select.Item
                        key={option.value}
                        value={option.value!}
                        className={clsx(
                          'relative flex items-center text-xs rounded h-6 pr-8 pl-6',
                          option.value === value ? 'text-[#363636]' : 'text-white'
                        )}
                      >
                        <Select.ItemText>
                          <span className="text-[15px] font-semibold tracking-tight">
                            {option.description}
                          </span>
                        </Select.ItemText>
                        <Select.ItemIndicator className="absolute inline-flex items-center justify-center left-0 h-6 text-[#f42a39]">
                          <Check />
                        </Select.ItemIndicator>
                      </Select.Item>
                    ))}
                  </Select.Viewport>
                  <Select.ScrollDownButton className="">
                    <CaretDown />
                  </Select.ScrollDownButton>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </div>
        </div>
        <a
          href="https://www.instagram.com/blend.365/"
          target="_blank"
          className="lg:flex hidden flex-row  items-center gap-4 p-1 mt-5 lg:mt-0 rounded-full  bg-[#f42a375d]/70"
        >
          <div className="bg-[#f42a37] rounded-full p-2">
            <Instagram className="text-white w-6 h-6" />
          </div>
          <p className="text-white font-inter font-medium text-base pr-3">
            Acompanhe os jackpots no Instagram.
          </p>
        </a>
      </div>

      <div className="flex flex-row pb-2 pt-5 gap-5 lg:gap-3 pl-5 lg:ml-44 lg:pl-0 lg:pr-24 mt-8 overflow-auto lg:scrollbar max-w-full max-h-[360px] lg:max-h-[395px]">
        {results && results.length > 0 ? (
          results?.map((jackpot) => (
            <ResultCard
              key={jackpot.id}
              id={jackpot.id}
              date={jackpot.date}
              selected_color={jackpot.selected_color}
              price={jackpot.price}
              winners={jackpot.winners}
              type={jackpot.type}
              winner_ticket={jackpot.winner_ticket}
            />
          ))
        ) : (
          <p className="text-white text-sm">
            Ainda não existem jackpots realizados para esses parâmetros
          </p>
        )}
      </div>
      <a
        href="https://www.instagram.com/blend.365/"
        target="_blank"
        className="lg:hidden flex flex-row  items-center gap-4 p-1 mt-5 lg:mt-0 rounded-full  bg-[#f42a375d]/70"
      >
        <div className="bg-[#f42a37] rounded-full p-2">
          <Instagram className="text-white w-6 h-6" />
        </div>
        <p className="text-white font-inter font-medium text-base pr-3">
          Acompanhe os jackpots no Instagram.
        </p>
      </a>
    </section>
  );
}
