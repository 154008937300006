import * as Dialog from "@radix-ui/react-dialog";

import { X } from "lucide-react";
import {
  Info,
  MagnifyingGlass,
  MapPin,
  Ticket,
  Trophy,
  UsersFour,
} from "@phosphor-icons/react";
import ParticipantsListPagination from "../ParticipantsListPagination";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store";
import ParticipantsListCard from "../ParticipantsListCard";
import { JackpotWinnerProps, winnerProps } from "@/@types/AppTypes";
import * as HoverCard from "@radix-ui/react-hover-card";
export interface ModalProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  winners: JackpotWinnerProps[];
  selected_color: number;
  ticket_number: string;
}

export default function DisplayWinnersModal({
  isOpen,
  setIsOpen,
  winners,
  selected_color,
  ticket_number,
}: ModalProps) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="w-screen h-screen bg-black/80 data-[state=open]:animate-overlayShow fixed inset-0 z-40" />
        <Dialog.Content
          className={`fixed p-3 lg:p-10 bg-[#ffffff] border-2 border-darkText rounded-2xl w-[330px] md:w-[580px] lg:w-[750px] max-w-[98vw] max-h-[85vh] lg:max-h-[90vh] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50 overflow-y-auto overflow-x-hidden data-[state=open]:animate-contentShow shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none`}
        >
          <Dialog.Close className="absolute right-6 top-7 text-zinc-400 rounded-lg hover:text-zinc-200 focus:outline-none focus:ring-2 focus:ring-violet-600 focus:ring-offset-2 focus:ring-offset-zinc-900">
            <X size={20} aria-label="Fechar" />
          </Dialog.Close>

          <Dialog.Title className="flex flex-col lg:w-full items-center justify-center mb-2 lg:mb-5 gap-3 rounded-t-lg">
            <div className="flex flex-col lg:flex-row lg:w-full lg:justify-between">
              <div className="flex flex-col lg:flex-row items-center lg:gap-[14px]">
                <div className="relative">
                  <Trophy
                    className="text-[#3A3A3A] w-16 lg:w-12 h-16 lg:h-12"
                    weight="fill"
                  />
                </div>

                <div className="flex flex-col lg:flex-row items-center lg:items-end justify-center gap-1 lg:gap-3">
                  <h1 className="font-inter text-[#3A3A3A] text-base lg:text-[33px] lg:leading-[40px] font-bold">
                    Lista de ganhadores
                  </h1>
                </div>
              </div>

              <div className="flex flex-row gap-2 items-center justify-center px-3 py-[5px] rounded-full bg-[#f42a37] text-white">
                <Trophy className="w-5 h-5 lg:w-6 lg:h-6" weight="bold" />
                <span className="flex items-center gap-2 text-[15px] leading-[18px] font-medium">
                  Total de ganhadores:{" "}
                  <span className="text-[19px] leading-[23px]">
                    {winners.length}
                  </span>
                </span>
              </div>
            </div>

            <div className="flex flex-row gap-2 items-center justify-center px-3 py-[5px] rounded-full bg-[#6D6D6D] text-white">
              <Ticket className="w-5 h-5 lg:w-6 lg:h-6" weight="bold" />
              <span className="flex items-center gap-2 text-[15px] leading-[18px] font-medium">
                Ticket vencedor:{" "}
                <span className="text-[19px] leading-[23px]">
                  {ticket_number}
                </span>
              </span>
            </div>

            <div
              className={`${
                selected_color <= 3 ? "flex" : "hidden"
              } items-center gap-2 text-[15px] leading-[18px] font-medium`}
            >
              <span>Cor ganhadora: </span>
              {selected_color <= 3 && (
                <div className="w-6 h-6">
                  <svg
                    className={`w-full h-full ${
                      selected_color === 0
                        ? "text-[#DDBE55]"
                        : selected_color === 1
                        ? "text-[#39B54A]"
                        : selected_color === 2
                        ? "text-[#0078E8]"
                        : "text-[#f42a37]"
                    } fill-current`}
                    viewBox="0 0 300 300"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M104.723 16.9641C93.8074 17.0283 83.3569 21.3931 75.638 29.112C67.9192 36.8308 63.5544 47.2814 63.4901 58.1972L63.4184 64.3284L57.341 64.3464C46.7271 64.8252 36.7066 69.3786 29.365 77.0589C22.0234 84.7392 17.9262 94.9548 17.9262 105.58C17.9262 116.204 22.0234 126.42 29.365 134.1C36.7066 141.78 46.7271 146.334 57.341 146.813L142.21 146.92C143.215 146.913 144.178 146.51 144.889 145.799C145.6 145.088 146.003 144.125 146.01 143.12L146.01 58.251C145.96 47.3165 141.594 36.8442 133.862 29.1122C126.13 21.3803 115.658 17.0143 104.723 16.9641Z"
                      fill="currentColor"
                    />
                    <path
                      d="M104.723 283.036C93.8074 282.972 83.3569 278.607 75.638 270.888C67.9192 263.169 63.5544 252.719 63.4901 241.803L63.4184 235.672L57.341 235.654C46.7271 235.175 36.7066 230.621 29.365 222.941C22.0234 215.261 17.9262 205.045 17.9262 194.42C17.9262 183.796 22.0234 173.58 29.365 165.9C36.7066 158.22 46.7271 153.666 57.341 153.187L142.21 153.08C143.215 153.087 144.178 153.49 144.889 154.201C145.6 154.912 146.003 155.875 146.01 156.88L146.01 241.749C145.96 252.683 141.594 263.156 133.862 270.888C126.13 278.62 115.658 282.986 104.723 283.036Z"
                      fill="currentColor"
                    />
                    <path
                      d="M195.1 16.9641C206.016 17.0283 216.466 21.3931 224.185 29.112C231.904 36.8308 236.269 47.2814 236.333 58.1972L236.405 64.3284L242.482 64.3464C253.096 64.8252 263.117 69.3786 270.458 77.0589C277.8 84.7392 281.897 94.9548 281.897 105.58C281.897 116.204 277.8 126.42 270.458 134.1C263.117 141.78 253.096 146.334 242.482 146.813L157.614 146.92C156.608 146.913 155.645 146.51 154.934 145.799C154.223 145.088 153.82 144.125 153.813 143.12L153.813 58.251C153.863 47.3165 158.229 36.8442 165.961 29.1122C173.693 21.3803 184.165 17.0143 195.1 16.9641Z"
                      fill="currentColor"
                    />
                    <path
                      d="M195.1 283.036C206.016 282.972 216.466 278.607 224.185 270.888C231.904 263.169 236.269 252.719 236.333 241.803L236.405 235.672L242.482 235.654C253.096 235.175 263.117 230.621 270.458 222.941C277.8 215.261 281.897 205.045 281.897 194.42C281.897 183.796 277.8 173.58 270.458 165.9C263.117 158.22 253.096 153.666 242.482 153.187L157.614 153.08C156.608 153.087 155.645 153.49 154.934 154.201C154.223 154.912 153.82 155.875 153.813 156.88L153.813 241.749C153.863 252.683 158.229 263.156 165.961 270.888C173.693 278.62 184.165 282.986 195.1 283.036Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              )}
            </div>
          </Dialog.Title>

          <div className="flex flex-col w-full gap-3">
            {winners.map((winner, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row w-full p-2 gap-2 items-center justify-between border border-[#E7E7E7] rounded-xl"
              >
                <div className="flex flex-row w-full lg:w-[250px] items-center justify-between gap-1">
                  <div className="flex flex-row items-center justify-start gap-1 ml-1">
                    <span className="min-w-[100px] lg:w-32 text-[#454545] font-medium text-[15px] lg:text-[16px]">
                      {winner.name}
                    </span>
                    <span className="min-w-[85px] lg:w-28 text-[#616161] font-medium text-[12px] lg:text-[14px]">
                      {winner.document}
                    </span>
                  </div>

                  {winner.selected_color > 3 && (
                    <div className="flex lg:hidden flex-row px-3 py-2 gap-1 bg-[#f42a37] rounded-full">
                      <span className="text-white text-[11px] font-medium lg:text-sm">
                        {winner.selected_color === 4
                          ? "HORA"
                          : winner.selected_color === 5
                          ? "DIA"
                          : winner.selected_color === 6
                          ? "SEMANA"
                          : winner.selected_color === 7
                          ? "MÊS"
                          : "ANO"}
                      </span>
                    </div>
                  )}
                </div>

                <div className="flex flex-row w-full lg:!w-full items-center justify-between gap-2">
                  <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row px-2 py-1 gap-1 border border-[#E4E4E4] rounded-full">
                      <MapPin className="w-4 h-4 lg:w-5 lg:h-5 text-[#949494]" />
                      <span className="uppercase text-[#949494] text-[10px] lg:text-sm">
                        {winner.location}
                      </span>
                    </div>
                    {winner.selected_color <= 3 && (
                      <div className="w-6 h-6">
                        <svg
                          className={`w-full h-full ${
                            winner.selected_color === 0
                              ? "text-[#DDBE55]"
                              : winner.selected_color === 1
                              ? "text-[#39B54A]"
                              : winner.selected_color === 2
                              ? "text-[#0078E8]"
                              : "text-[#f42a37]"
                          } fill-current`}
                          viewBox="0 0 300 300"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M104.723 16.9641C93.8074 17.0283 83.3569 21.3931 75.638 29.112C67.9192 36.8308 63.5544 47.2814 63.4901 58.1972L63.4184 64.3284L57.341 64.3464C46.7271 64.8252 36.7066 69.3786 29.365 77.0589C22.0234 84.7392 17.9262 94.9548 17.9262 105.58C17.9262 116.204 22.0234 126.42 29.365 134.1C36.7066 141.78 46.7271 146.334 57.341 146.813L142.21 146.92C143.215 146.913 144.178 146.51 144.889 145.799C145.6 145.088 146.003 144.125 146.01 143.12L146.01 58.251C145.96 47.3165 141.594 36.8442 133.862 29.1122C126.13 21.3803 115.658 17.0143 104.723 16.9641Z"
                            fill="currentColor"
                          />
                          <path
                            d="M104.723 283.036C93.8074 282.972 83.3569 278.607 75.638 270.888C67.9192 263.169 63.5544 252.719 63.4901 241.803L63.4184 235.672L57.341 235.654C46.7271 235.175 36.7066 230.621 29.365 222.941C22.0234 215.261 17.9262 205.045 17.9262 194.42C17.9262 183.796 22.0234 173.58 29.365 165.9C36.7066 158.22 46.7271 153.666 57.341 153.187L142.21 153.08C143.215 153.087 144.178 153.49 144.889 154.201C145.6 154.912 146.003 155.875 146.01 156.88L146.01 241.749C145.96 252.683 141.594 263.156 133.862 270.888C126.13 278.62 115.658 282.986 104.723 283.036Z"
                            fill="currentColor"
                          />
                          <path
                            d="M195.1 16.9641C206.016 17.0283 216.466 21.3931 224.185 29.112C231.904 36.8308 236.269 47.2814 236.333 58.1972L236.405 64.3284L242.482 64.3464C253.096 64.8252 263.117 69.3786 270.458 77.0589C277.8 84.7392 281.897 94.9548 281.897 105.58C281.897 116.204 277.8 126.42 270.458 134.1C263.117 141.78 253.096 146.334 242.482 146.813L157.614 146.92C156.608 146.913 155.645 146.51 154.934 145.799C154.223 145.088 153.82 144.125 153.813 143.12L153.813 58.251C153.863 47.3165 158.229 36.8442 165.961 29.1122C173.693 21.3803 184.165 17.0143 195.1 16.9641Z"
                            fill="currentColor"
                          />
                          <path
                            d="M195.1 283.036C206.016 282.972 216.466 278.607 224.185 270.888C231.904 263.169 236.269 252.719 236.333 241.803L236.405 235.672L242.482 235.654C253.096 235.175 263.117 230.621 270.458 222.941C277.8 215.261 281.897 205.045 281.897 194.42C281.897 183.796 277.8 173.58 270.458 165.9C263.117 158.22 253.096 153.666 242.482 153.187L157.614 153.08C156.608 153.087 155.645 153.49 154.934 154.201C154.223 154.912 153.82 155.875 153.813 156.88L153.813 241.749C153.863 252.683 158.229 263.156 165.961 270.888C173.693 278.62 184.165 282.986 195.1 283.036Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  <div className="lg:flex lg:flex-row lg:gap-3">
                    {winner.selected_color > 3 && (
                      <div className="hidden lg:flex flex-row px-3 py-2 gap-1 bg-[#f42a37] rounded-full">
                        <span className="text-white text-[11px] font-medium lg:text-sm">
                          {winner.selected_color === 4
                            ? "HORA"
                            : winner.selected_color === 5
                            ? "DIA"
                            : winner.selected_color === 6
                            ? "SEMANA"
                            : winner.selected_color === 7
                            ? "MÊS"
                            : "ANO"}
                        </span>
                      </div>
                    )}
                    <span className="px-3 py-1 text-[#03963F] bg-[#f42a37]/10 text-[15px] lg:text-[15px] text-center font-medium  rounded-full">
                      {new Intl.NumberFormat("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }).format(Number(winner.price))}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
