import { ParticipantProps } from "@/@types/AppTypes";
import { MapPin } from "lucide-react";

export interface ParticipantRowProps {
  participant: ParticipantProps;
  fixed_pot: boolean;
  index: number;
}

export default function LastParticipantRowUpperSection({
  participant,
  fixed_pot,
  index,
}: ParticipantRowProps) {
  return (
    <div
      className={`flex flex-row w-full px-1 py-[3px] items-center justify-between ${
        index <= 0 ? "opacity-95" : index <= 1 ? "opacity-60" : "opacity-20"
      }`}
    >
      <div className="flex flex-row items-center justify-center gap-1">
        <span className="w-[70px] text-[#454545] font-medium text-[11px] truncate">
          {participant.name}
        </span>
        <span className="w-[70px] text-[#616161] font-medium text-[10px]">
          {participant.document}
        </span>
      </div>

      <div className="flex min-w-16 flex-row items-center justify-center gap-2">
        {!fixed_pot && (
          <span className="w-16 px-2 lg:px-3 py-1 text-[#454545] text-[11px] text-center font-medium bg-[#f42a37]/10 rounded-full">
            {new Intl.NumberFormat("pt-br", {
              style: "currency",
              currency: "BRL",
            }).format(Number(participant.price))}
          </span>
        )}
      </div>
    </div>
  );
}
