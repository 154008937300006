import { ParticipantProps } from "@/@types/AppTypes";
import { Ticket } from "@phosphor-icons/react";
import { MapPin } from "lucide-react";

export interface ParticipantRowProps {
  participant: ParticipantProps;
  fixed_pot: boolean;
  withoutPrice?: boolean;
}

export default function ParticipantRow({
  participant,
  fixed_pot,
  withoutPrice = false,
}: ParticipantRowProps) {
  return (
    <div className="flex flex-col w-full p-2 gap-2 items-center justify-between border border-[#E7E7E7] rounded-xl">
      <div className="flex flex-row w-full items-center justify-start gap-1 pl-1">
        <span className="min-w-[100px] lg:w-32 text-[#454545] font-medium text-[15px] lg:text-[16px]">
          {participant.name}
        </span>
        <span className="min-w-[95px] lg:w-28 text-[#616161] font-medium text-[12px] lg:text-[14px]">
          {participant.document}
        </span>
      </div>

      <div
        className={`flex flex-row w-full items-center justify-between gap-1`}
      >
        <div className="flex flex-row gap-2 items-center px-3 py-1 text-[15px] lg:text-[15px] text-center font-medium bg-[#f42a371A] rounded-full">
          <Ticket className="w-[22px] h-[22px] text-[#03963F]" weight="fill" />
          <span className="text-[#03963F]">{participant.tickets_amount}</span>
        </div>
      </div>
    </div>
  );
}
