import * as Dialog from "@radix-ui/react-dialog";
import dayjs from "dayjs";
import * as Accordion from "@radix-ui/react-accordion";
import { JackpotProps, ProfileResponse } from "@/@types/AppTypes";
import ParticipantsByIdModal from "./ParticipantsByIdModal";
import { useState } from "react";
import MyTicketsTopMenu from "../../partials/MyTicketsTopMenu";
import {
  ArrowLeft,
  CalendarBlank,
  CaretDown,
  CaretRight,
  MinusCircle,
  PlusCircle,
} from "@phosphor-icons/react";

export interface ModalProps {
  isOpen: boolean;
  jackpots: JackpotProps[];
  profile: ProfileResponse;
  modalJackpots: {
    id: string;
    totalTickets: number;
  }[];
  setIsOpen: (data: boolean) => void;
}

export default function MyTicketsModal({
  isOpen,
  setIsOpen,
  profile,
  jackpots,
  modalJackpots,
}: ModalProps) {
  const [openParticipantsByIdModal, setOpenParticipantsByIdModal] =
    useState(false);
  const [showMore, setShowMore] = useState(false);
  const jackpotTypes = ["hour", "day", "week", "month", "year"];
  const jackpotTickets = profile
    ? [
        profile.tickets_hour || [],
        profile.tickets_day || [],
        profile.tickets_week || [],
        profile.tickets_month || [],
        profile.tickets_year || [],
      ]
    : [[], [], [], [], []];
  const concatJackpotsArray = jackpots
    ?.map((jackpot) => {
      const matchingJackpotB = modalJackpots?.find(
        (jackpotB) => jackpotB.id === jackpot.id
      );
      let toBeReturned = {};
      if (matchingJackpotB) {
        const aux = {
          ...jackpot,
          totalTickets: matchingJackpotB.totalTickets,
        };
        toBeReturned = aux;
      }
      const jackpotProps = toBeReturned as JackpotProps;
      jackpotProps.tickets = jackpotTickets[jackpotTypes.indexOf(jackpot.type)];
      return jackpotProps;
    })
    .filter((jp) => jp.type);
  const hourJackpot = jackpots?.filter((jp) => jp.type === "hour")[0];
  const date = new Date(hourJackpot?.end_datetime);
  const hasHourTickets = Number(profile?.hour_tickets) > 0;

  function translateJackpotType(type: string) {
    switch (type) {
      case "day":
        return "DO DIA";
      case "hour":
        return "DA HORA";
      case "week":
        return "DA SEMANA";
      case "month":
        return "DO MÊS";
      case "year":
        return "DO ANO";
      default:
        return "";
    }
  }

  return (
    <>
      {hourJackpot && (
        <ParticipantsByIdModal
          isOpen={openParticipantsByIdModal}
          setIsOpen={setOpenParticipantsByIdModal}
          jackpot={hourJackpot}
        />
      )}
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="w-screen h-screen bg-black/80 data-[state=open]:animate-overlayShow fixed inset-0 z-30" />
          <Dialog.Content
            className={`fixed top-0 bg-[#FAFAFA] ${
              /iPad|iPhone|iPod/.test(navigator.userAgent) ? "pb-20" : ""
            } w-screen h-screen left-[50%] translate-x-[-50%] z-40 overflow-y-auto overflow-x-hidden data-[state=open]:animate-receiptsShow shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none`}
          >
            <MyTicketsTopMenu
              onModalOpen
              profile={profile}
              goBack={setIsOpen}
            />

            {concatJackpotsArray &&
            concatJackpotsArray.length > 0 &&
            concatJackpotsArray.some(
              (jackpot) => jackpot.tickets.length > 0
            ) ? (
              <div className="flex flex-col w-full items-center p-3 lg:p-6 gap-5 lg:gap-6">
                <Accordion.Root
                  className="w-full max-w-[1000px]  p-1 lg:p-2 gap-3"
                  type="multiple"
                >
                  {concatJackpotsArray?.map((jackpot) =>
                    jackpot.tickets.length <= 0 ? (
                      <div />
                    ) : (
                      <Accordion.Item
                        value={jackpot.id}
                        key={jackpot.id}
                        className="p-2 lg:p-5 bg-white focus-within:shadow-[#f42a37] shadow overflow-hidden first:mt-0 mt-3 rounded-[18px] focus-within:relative focus-within:z-10 focus-within:shadow-[0_0_0_2px] data-[state=open]:border-b-4 data-[state=open]:border-[#f42a37]"
                      >
                        <Accordion.Header className="flex ">
                          <Accordion.Trigger className="font-bold text-[15px] lg:text-[25px] text-black shadow-transparent hover:bg-white group flex-row h-[70px] lg:h-[60px] flex-1 cursor-default flex items-center justify-between bg-white px-6 py-4 lg:py-5 leading-none shadow-[0_1px_0] outline-none transition-all">
                            <div className="flex flex-col items-start justify-start gap-1">
                              <div className="flex flex-row w-full items-center justify-start  gap-3 lg:gap-4">
                                <CalendarBlank className="w-6 lg:w-7 h-6 lg:h-7 text-[#5F5F5F]" />
                                <span className="font-bold text-[13px] lg:text-[15px] xl:text-[16px] tracking-[2px] lg:leading-[50px] uppercase text-[#5F5F5F]">
                                  JACKPOT {translateJackpotType(jackpot.type)}
                                </span>
                              </div>

                              <span
                                className={` text-[#383838] font-medium text-[10px] uppercase`}
                              >
                                VOCÊ POSSUI {jackpot.tickets.length}{" "}
                                {jackpot.tickets.length > 1
                                  ? "TICKETS"
                                  : "TICKET"}
                              </span>
                            </div>

                            <div className="flex flex-col-reverse lg:flex-row w-fit h-fit items-end lg:items-center justify-between gap-1 lg:gap-2 transition-all">
                              <div className="flex items-center justify-center p-2 w-10 h-10 bg-white rounded-full ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:hidden ">
                                <CaretRight
                                  className="text-[#f42a37] font-bold w-[14px] h-[14px]"
                                  aria-hidden
                                  weight="bold"
                                />
                              </div>
                              <div className="flex items-center justify-center p-2 w-10 h-10 bg-[#f42a37] text-white rounded-full ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=closed]:hidden ">
                                <CaretDown
                                  weight="bold"
                                  className="text-white font-bold w-[14px] h-[14px]"
                                  aria-hidden
                                />
                              </div>
                            </div>
                          </Accordion.Trigger>
                        </Accordion.Header>
                        <Accordion.Content className="pb-4 px-6 text-black bg-white data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[15px]">
                          <div className="flex flex-col w-full h-fit scroll-smooth">
                            <div className="flex flex-row w-full gap-1 text-[15px] font-normal text-[#333333] mb-3">
                              <span className="text-[15px] font-normal text-[#333333]">
                                {dayjs(jackpot.end_datetime).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                              <span className="text-[15px] font-normal text-[#333333]">
                                -
                              </span>
                              <span className="text-[15px] font-normal text-[#333333]">
                                {dayjs(jackpot.end_datetime).format("HH:mm:ss")}
                              </span>
                            </div>

                            <div className="flex flex-col w-full border-b-2 border-[#E9E9E9] pb-3">
                              <div
                                className={`relative flex flex-wrap ${
                                  showMore
                                    ? "max-h-fit"
                                    : "max-h-[200px] md:max-h-[60px]  overflow-y-clip"
                                } gap-4 items-center justify-around transition-all duration-700`}
                              >
                                <div
                                  className={`transition-all duration-700 ${
                                    showMore || jackpot.tickets?.length < 9
                                      ? "hidden"
                                      : "absolute w-full h-full bg-gradient-to-t from-white to-transparent"
                                  }`}
                                ></div>
                                {jackpot.tickets?.map((value) => (
                                  <div
                                    className="flex w-[90px] h-[40px] items-center justify-center text-[#383838] text-[17px] leading-[21px] font-medium  bg-[#EFEFEF] rounded-lg"
                                    key={value}
                                  >
                                    {Number(value).toLocaleString("en-US", {
                                      minimumIntegerDigits: 6,
                                      useGrouping: false,
                                    })}
                                  </div>
                                ))}
                              </div>
                              <button
                                type="button"
                                className={`${
                                  jackpot.tickets?.length > 9
                                    ? "flex"
                                    : "hidden"
                                } flex-row items-center justify-center gap-2 mt-2`}
                                onClick={() => setShowMore(!showMore)}
                              >
                                {showMore ? (
                                  <>
                                    <MinusCircle
                                      className="w-6 h-6 text-[#323232]"
                                      weight="bold"
                                    />
                                    <span className="font-bold text-[13px] leading-[19px] tracking-[2px] text-[#323232] underline">
                                      VER MENOS
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <PlusCircle
                                      className="w-6 h-6 text-[#323232]"
                                      weight="bold"
                                    />
                                    <span className="font-bold text-[13px] leading-[19px] tracking-[2px] text-[#323232] underline">
                                      VER MAIS
                                    </span>
                                  </>
                                )}
                              </button>
                            </div>

                            {jackpot.type === "hour" && (
                              <div className="flex flex-row w-full items-center justify-start gap-2 mt-3">
                                {profile.color_prices?.map((color, index) => (
                                  <div
                                    className={`flex-row items-center justify-start py-1 px-3 gap-3 rounded-full ${
                                      color > 0 ? "flex" : "hidden"
                                    } ${
                                      index === 0
                                        ? "bg-[#DDBE55]/10"
                                        : index === 1
                                        ? "bg-[#39B54A]/10"
                                        : index === 2
                                        ? "bg-[#0078E8]/10"
                                        : "bg-[#f42a37]/10"
                                    }`}
                                  >
                                    <div className="w-4 h-4">
                                      <svg
                                        className={`w-full h-full ${
                                          index === 0
                                            ? "text-[#DDBE55]"
                                            : index === 1
                                            ? "text-[#39B54A]"
                                            : index === 2
                                            ? "text-[#0078E8]"
                                            : "text-[#f42a37]"
                                        } fill-current`}
                                        viewBox="0 0 20 19"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M3.192 3.1871L9.17068 9.15613C9.19704 8.81858 9.22221 8.53672 9.24451 8.33144C9.25729 8.21351 9.27939 8.06225 9.30485 7.88704C9.35341 7.55394 9.4199 7.09756 9.46231 6.59163C9.50106 6.12932 9.51859 5.68314 9.51453 5.26535C9.51186 5.00299 9.50066 4.74586 9.48114 4.50091C9.34132 2.74982 8.76946 1.47935 7.78152 0.724796C6.43527 -0.303399 5.29849 -0.0140242 4.7407 0.249925C4.4578 0.383878 4.20293 0.566901 3.98463 0.784847C3.44766 1.32096 3.13274 2.06789 3.17198 2.81017L3.192 3.1871Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M4.50812 9.46606C4.75347 9.48555 5.01111 9.49683 5.2737 9.4995C5.69216 9.50346 6.13906 9.48605 6.60212 9.44736C7.10887 9.40502 7.56598 9.33864 7.89962 9.29016C8.07511 9.26474 8.22662 9.24268 8.34474 9.22981C8.55016 9.20765 8.83247 9.18233 9.17067 9.15611L3.19209 3.18708L2.81455 3.1671C1.76874 3.11199 0.714204 3.75604 0.250159 4.73328C-0.0143166 5.29007 -0.304159 6.42501 0.725797 7.76919C1.48157 8.75554 2.7542 9.32637 4.50802 9.46606H4.50812Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M3.19201 15.8129L9.1707 9.84386C8.83259 9.81755 8.55028 9.79242 8.34467 9.77016C8.22655 9.7574 8.07504 9.73534 7.89955 9.70991C7.56591 9.66144 7.1088 9.59505 6.60205 9.55271C6.13899 9.51403 5.69209 9.49652 5.27363 9.50057C5.01084 9.50324 4.7533 9.51442 4.50795 9.53391C2.75403 9.6735 1.48149 10.2444 0.725724 11.2308C-0.304134 12.575 -0.0142914 13.7099 0.250085 14.2667C0.384254 14.5491 0.567574 14.8036 0.785872 15.0215C1.32285 15.5577 2.07099 15.8721 2.81447 15.8329L3.19201 15.8129Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M9.48114 14.499C9.50066 14.2541 9.51196 13.9968 9.51463 13.7347C9.5186 13.3169 9.50116 12.8707 9.46241 12.4084C9.42 11.9025 9.35351 11.4461 9.30496 11.113C9.27949 10.9378 9.25739 10.7865 9.24451 10.6686C9.22231 10.4635 9.19695 10.1816 9.17069 9.84399L3.192 15.813L3.17199 16.19C3.11679 17.2341 3.76188 18.2869 4.7407 18.7502C5.29839 19.0142 6.43517 19.3036 7.78152 18.2753C8.76947 17.5208 9.34122 16.2502 9.48114 14.4992V14.499Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M15.8383 15.813L9.85966 9.84399C9.8333 10.1815 9.80813 10.4634 9.78573 10.6687C9.77295 10.7866 9.75085 10.9379 9.72539 11.1131C9.67683 11.4462 9.61034 11.9026 9.56793 12.4085C9.52919 12.8708 9.51165 13.317 9.51571 13.7348C9.51839 13.9971 9.52958 14.2543 9.5491 14.4992C9.68892 16.2503 10.2608 17.5208 11.2487 18.2753C12.5951 19.3036 13.7319 19.0142 14.2895 18.7502C14.5724 18.6162 14.8273 18.4332 15.0456 18.2153C15.5826 17.6792 15.8975 16.9322 15.8583 16.19L15.8382 15.813H15.8383Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M14.5222 9.53399C14.2768 9.5145 14.0192 9.50322 13.7566 9.50055C13.3381 9.49659 12.8912 9.514 12.4282 9.55269C11.9214 9.59503 11.4643 9.66141 11.1307 9.70989C10.9552 9.73531 10.8037 9.75738 10.6855 9.77024C10.4801 9.7924 10.1978 9.81772 9.85962 9.84394L15.8383 15.813L16.2158 15.833C17.2617 15.8881 18.3162 15.244 18.7802 14.2668C19.0447 13.71 19.3345 12.575 18.3046 11.2309C17.5488 10.2445 16.2762 9.67368 14.5224 9.53399H14.5222Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M15.8613 3.18698L9.88257 9.15601C10.2207 9.18233 10.503 9.20745 10.7086 9.22981C10.8267 9.24258 10.9782 9.26464 11.1537 9.29006C11.4874 9.33854 11.9445 9.40492 12.4512 9.44726C12.9143 9.48595 13.3612 9.50346 13.7796 9.4994C14.0424 9.49673 14.3 9.48555 14.5453 9.46606C16.2992 9.32647 17.5718 8.75554 18.3275 7.76919C19.3575 6.42501 19.0677 5.29007 18.8032 4.73328C18.669 4.45083 18.4857 4.19638 18.2674 3.97843C17.7304 3.44232 16.9823 3.12792 16.2388 3.1671L15.8613 3.18708V3.18698Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M9.57213 4.50094C9.55261 4.74589 9.54131 5.00311 9.53864 5.26528C9.53467 5.68307 9.55211 6.12925 9.59086 6.59156C9.63327 7.09749 9.69976 7.55386 9.74831 7.88697C9.77378 8.06217 9.79588 8.21344 9.80876 8.33137C9.83096 8.53645 9.85632 8.81831 9.88258 9.15596L15.8613 3.18693L15.8813 2.81C15.9365 1.76588 15.2914 0.713051 14.3126 0.249755C13.7549 -0.0142935 12.6181 -0.303668 11.2717 0.724626C10.2838 1.47918 9.71205 2.74975 9.57213 4.50074V4.50094Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </div>
                                    <span
                                      className={`text-[15px] font-medium ${
                                        index === 0
                                          ? "text-[#DDBE55]"
                                          : index === 1
                                          ? "text-[#39B54A]"
                                          : index === 2
                                          ? "text-[#0078E8]"
                                          : "text-[#f42a37]"
                                      }`}
                                    >
                                      {new Intl.NumberFormat("pt-br", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(Number(color))}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </Accordion.Content>
                      </Accordion.Item>
                    )
                  )}
                </Accordion.Root>
              </div>
            ) : (
              <div className="text-center text-[19px] lg:text-[21px] text-[#5F5F5F] font-medium italic px-5 mt-10">
                Ops, parece que atualmente você não está participando de nenhum
                jackpot.
              </div>
            )}
            <button
              className="absolute bottom-2 left-2 flex w-fit flex-row items-center justify-center gap-3 px-4 py-2 bg-[#f42a372c] rounded-full"
              onClick={() => setIsOpen(false)}
            >
              <button
                type="button"
                className=""
                onClick={() => setIsOpen(false)}
              >
                <ArrowLeft className="text-white w-6 h-6" weight="bold" />
              </button>

              <span className="text-sm text-[#f42a39]">Voltar</span>
            </button>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
