import { ProfileResponse } from "@/@types/AppTypes";
import {
  CaretDown,
  CaretUp,
  CurrencyDollar,
  CurrencyDollarSimple,
  Gift,
  InstagramLogo,
  Ticket,
  UserCircle,
  UsersFour,
  WhatsappLogo,
  X,
} from "@phosphor-icons/react";
import { WhatsappShareButton } from "react-share";
import * as Popover from "@radix-ui/react-popover";
import * as Tabs from "@radix-ui/react-tabs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store";
import MyTicketsModal from "../components/modals/MyTicketsModal";
import ReferralTab from "./ReferralTab";
import ToReceiveTab from "./ToReceiveTab";
import ReceivedTab from "./ReceivedTab";

export type TopMenuProps = {
  openMyTicketsModal: boolean;
  setOpenMyTicketsModal: (data: boolean) => void;
  profile?: ProfileResponse | null;
};

export default function TopMenu({
  openMyTicketsModal = false,
  setOpenMyTicketsModal,
  profile,
}: TopMenuProps) {
  const jackpots = useSelector(
    (state: RootState) => state.jackpots.openJackpot
  );
  const name = localStorage.getItem("name");

  function handleModalOpen() {
    if (openMyTicketsModal) {
      setOpenMyTicketsModal(false);
    } else {
      setOpenMyTicketsModal(true);
    }
  }

  return (
    <main
      className={`flex flex-col-reverse lg:flex-row w-full items-center justify-around lg:h-20 ${
        openMyTicketsModal ? "bg-[#f42a37]/50" : "bg-[#f42a37]/30"
      } `}
    >
      <MyTicketsModal
        isOpen={openMyTicketsModal}
        setIsOpen={setOpenMyTicketsModal}
        profile={profile!}
        jackpots={jackpots!}
        modalJackpots={jackpots!}
      />
      <div className="flex w-full lg:w-fit flex-col lg:flex-row p-4 gap-2 lg:gap-8 items-center justify-center lg:justify-around ">
        <div className="flex w-full lg:w-fit flex-row items-center justify-between lg:justify-center lg:gap-8">
          <div className="flex flex-row gap-1 items-center justify-center">
            <UserCircle className="text-white w-6 h-6" weight="bold" />
            <span className="text-white font-semibold text-[16px] lg:text-[21px]">
              Olá, {name ? name.split(" ")[0] : "Cliente"}!
            </span>
          </div>

          <div className="hidden lg:flex flex-row items-center justify-around h-10 py-2 px-6 bg-[#f42a375d] rounded-full">
            <CurrencyDollarSimple
              className="text-white w-6 h-6"
              weight="bold"
            />

            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <span>Meus Ganhos</span>
              <div className="flex flex-col gap-[2px]">
                <div className="flex flex-row items-center justify-center gap-2">
                  <p className="font-semibold">R$</p>
                  <p className="font-semibold">
                    {new Intl.NumberFormat("pt-br", {
                      style: "currency",
                      currency: "BRL",
                      currencyDisplay: "code",
                    })
                      .format(
                        Number(profile ? Number(profile?.total_purchases) : 0)
                      )
                      .replace("BRL", "")
                      .trim()}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="flex flex-row items-center justify-between py-2 px-4 gap-5 h-10 bg-[#f42a39] rounded-full"
            onClick={handleModalOpen}
          >
            <div className="flex flex-row gap-1 items-center justify-center">
              <Ticket className="text-[#002F13] w-5 h-5" weight="bold" />
              <span className="text-sm text-[#002F13] font-medium">
                Meus Tickets
              </span>
            </div>
          </button>
        </div>

        <div className="flex lg:hidden w-full flex-row items-center justify-center h-10 py-2 px-6 gap-2 bg-[#f42a375d] rounded-full">
          <CurrencyDollarSimple
            className="text-white w-6 h-6"
            weight="bold"
          />

          <div className="flex flex-row items-center justify-center gap-2 text-white">
            <span className="text-sm">Meus Ganhos</span>
            <div className="flex flex-col gap-[2px]">
              <div className="flex flex-row items-center justify-center gap-2">
                <p className="font-semibold">
                  {new Intl.NumberFormat("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }).format(
                    Number(profile ? Number(profile?.total_purchases) : 0)
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
