import Navbar from "../components/Navbar";

export default function ErrorPage() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-start gap-4 lg:gap-20 overflow-x-clip scroll-smooth bg-[#E9F1EF]">
      <Navbar />

      <div className="flex flex-col lg:flex-row w-full h-fit items-center justify-center px-4 lg:px-16 xl:px-24 2xl:px-32 gap-12 lg:gap-8 mt-20">
        <div className="flex flex-col w-full lg:max-w-[350px] h-fit items-center lg:items-start gap-4 lg:gap-14">
          <div className="flex flex-col w-full h-fit text-center lg:text-left lg:items-start gap-4 lg:gap-6">
            <span className="text-[#363636] text-[24px] lg:text-[40px] font-bold leading-[1.1] tracking-tight">
              Ops, esta página não foi encontrada
            </span>

            <span className="text-[#363636] text-[15px] lg:text-[20px] font-light leading-[1.1]">
              Parece que você se perdeu... Tente voltar para a página anteior ou
              acessar a Home
            </span>
          </div>

          <a
            className="flex items-center justify-center uppercase px-6 py-3 bg-[#f42a37] rounded-full text-[#E2E2F5] hover:text-white text-[14px] font-medium hover:scale-105 transition-all"
            href="/"
          >
            IR PARA A HOME
          </a>
        </div>

        <div className="relative w-[300px] lg:w-[470px] h-[170px] lg:h-[300px] items-center">
          <span className="absolute left-5 top-4 lg:-top-4 text-[#f42a37] font-extrabold text-[140px] lg:text-[250px]">
            4
          </span>
          <img
            src={"/duente_pote_central.png"}
            alt="Logo"
            width={350}
            height={300}
            className="absolute right-[30px] lg:right-[75px] w-[250px] lg:w-[350px] h-[200px] lg:h-[300px]"
          />
          <span className="absolute right-12 top-4 lg:-top-4 text-[#f42a37] font-extrabold text-[140px] lg:text-[250px] z-30">
            4
          </span>
        </div>
      </div>
    </main>
  );
}
