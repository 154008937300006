import { CheckSquareOffset, CopySimple, QrCode } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useQuery } from "@tanstack/react-query";
import { getPurchaseStatus } from "../lib/react-query/queryFunctions";
import TimeBarCountdown from "./TimeBarCountdown";
import dayjs from "dayjs";
import { padWithZeros } from "../partials/UpperSection";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store";

export type PaymentBodyProps = {
  purchaseId: string;
  qrcode: string;
  dueDatePayment?: Date;
  payment_expires?: number;
  setFormStep: (data: string) => void;
  setIsOpen: (data: boolean) => void;
};

export default function PaymentBody({
  purchaseId,
  qrcode,
  dueDatePayment,
  payment_expires,
  setFormStep,
  setIsOpen,
}: PaymentBodyProps) {
  const jackpots = useSelector(
    (state: RootState) => state.jackpots.openJackpot
  );
  const [showQRCode, setShowQRCode] = useState(false);
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const [countdown, setCountdown] = useState(100);
  const [remainingTime, setRemainingTime] = useState({
    minutes: "00",
    seconds: "00",
  });
  const { data } = useQuery({
    queryKey: ["getStatus", purchaseId],
    queryFn: getPurchaseStatus,
    refetchInterval: 5000,
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
      const authToken = localStorage.getItem("authToken");
      const purchasesLocalStorage = localStorage.getItem("purchases");
      let purchasesArray = purchasesLocalStorage
        ? JSON.parse(purchasesLocalStorage)
        : [];
      if (!authToken && purchaseId && !purchasesArray.includes(purchaseId)) {
        purchasesArray.push(purchaseId);
        localStorage.setItem("purchases", JSON.stringify(purchasesArray));
      }
      setShowQRCode(
        window.innerWidth > 1024 && window.innerHeight >= 768 ? true : false
      );
    }
    if (data?.payment_status === "paid") {
      localStorage.setItem("authToken", data?.token);
      localStorage.setItem("name", data?.name);
      localStorage.setItem("document", data?.document);
      setFormStep("paymentConfirmed");
    }
    const jackpot = jackpots?.filter((jack) => jack.type === "hour")[0];
    const localEndTime = dayjs(dueDatePayment)
      .tz(dayjs.tz.guess())
      .locale("pt-br");
    const updateRemainingTime = () => {
      const now = dayjs().tz(dayjs.tz.guess());
      const duration = dayjs.duration(localEndTime.diff(now));
      const progress =
        (duration.asMilliseconds() * 100) /
        (jackpot
          ? Number(jackpot?.payment_expire!) * 60000
          : Number(payment_expires) * 60000);

      const minutes = padWithZeros(Math.max(0, duration.minutes()), 2);
      const seconds = padWithZeros(Math.max(0, duration.seconds()), 2);

      setRemainingTime({ minutes, seconds });
      setCountdown(progress > 100 ? 100 : progress);
    };

    const timer = setInterval(updateRemainingTime, 1000);
    updateRemainingTime();

    return () => clearInterval(timer);
  }, [data, dueDatePayment]);

  function handleClipboardClick() {
    navigator.clipboard.writeText(qrcode);
    setIsCopiedToClipboard(true);
  }
  return (
    <div className="flex flex-col w-full h-fit items-center justify-between transition-all">
      {qrcode.length > 0 ? (
        <>
          <h1 className="text-[#f42a37] text-[10px] lg:text-lg text-center lg:leading-6 font-semibold border-b-[#9B9A9A] border-b pb-1">
            Efetue o pagamento e confirme a sua participação no jackpot!
          </h1>

          <h2 className="text-darkText text-center text-[10px] lg:text-sm italic font-normal mt-2">
            Siga os passos abaixo para realizar o pagamento!
          </h2>

          <div className="flex flex-row gap-2 w-full h-fit items-start justify-between">
            <TimeBarCountdown progress={countdown > 0 ? countdown : 0} />

            <span>
              {remainingTime.minutes}:{remainingTime.seconds}
            </span>
          </div>
          <div className="flex flex-col w-full h-fit gap-2 mt-2">
            <div className="flex flex-row w-full h-fit gap-2 items-center justify-start">
              <div className="flex w-5 lg:w-7 h-5 lg:h-7 text-xs lg:text-sm items-center justify-center p-1 lg:p-2 rounded-full bg-[#f42a37] text-white">
                1
              </div>
              <span className="text-darkText text-xs lg:text-sm">
                Copie o código{" "}
                <span className="font-bold text-darkText">PIX</span>
              </span>
            </div>

            <div className="flex flex-col w-full h-fit gap-2">
              <input
                type="text"
                value={qrcode}
                readOnly
                className={`appearance-none border bg-[#EEEEEE] text-[#f42a37] text-xs lg:text-sm placeholder:text-black/40 "border-tertiary"
                 shadow-sm focus:shadow-md focus:placeholder-white/40 focus:border-quaternary  transition  rounded-lg w-full p-2 leading-tight focus:outline-none focus:ring-white/70 focus:shadow-outline disabled:text-black/40`}
              />

              {showQRCode && (
                <div className="flex w-full text-center justify-center z-50">
                  <QRCodeCanvas
                    className="flex overflow-hidden text-white m-1"
                    value={qrcode}
                    bgColor="#fff"
                    fgColor="#000"
                    size={150}
                  />
                </div>
              )}

              <div className="flex flex-row w-full h-10 gap-2">
                <button
                  onClick={handleClipboardClick}
                  className={`flex flex-row items-center justify-around w-1/2 h-10 md:p-2 gap-[1px] lg:gap-1 text-[10px] md:text-sm text-white text-center font-medium uppercase rounded bg-[#f42a37] hover:bg-[#ae500d] ${
                    isCopiedToClipboard ? "border-2 border-purple-300" : ""
                  } focus:outline-none focus:ring-2 focus:ring-white shadow hover:shadow-lg transition transform hover:-translate-y-0.5`}
                >
                  {isCopiedToClipboard ? (
                    <CheckSquareOffset
                      className="w-6 h-6 text-white"
                      weight="fill"
                    />
                  ) : (
                    <CopySimple className="w-6 h-6 text-white" />
                  )}
                  {isCopiedToClipboard ? "Código Copiado" : "Copiar código PIX"}
                </button>

                <button
                  onClick={() => setShowQRCode(!showQRCode)}
                  className="flex flex-row items-center justify-around w-1/2 h-10 md:p-2 gap-[1px] lg:gap-1 text-[10px] md:text-sm text-white text-center font-medium uppercase rounded bg-[#f42a37] hover:bg-[#ae500d] focus:outline-none focus:ring-2 focus:ring-white shadow hover:shadow-lg transition transform hover:-translate-y-0.5"
                >
                  <QrCode className="w-6 h-6 text-white" />
                  {showQRCode ? "Esconder QRCODE" : "Ver QRCODE"}
                </button>
              </div>
            </div>

            <div className="flex flex-row w-full h-fit gap-2 items-center justify-start">
              <div className="flex w-5 lg:w-7 h-5 lg:h-7 text-xs lg:text-sm items-center justify-center p-1 lg:p-2 rounded-full bg-[#f42a37] text-white">
                2
              </div>
              <span className="text-darkText text-xs lg:text-sm">
                Abra o aplicativo do banco de sua prefêrencia
              </span>
            </div>

            <div className="flex flex-row w-full h-fit gap-2 items-center justify-start">
              <div className="flex w-5 lg:w-7 h-5 lg:h-7 text-xs lg:text-sm items-center justify-center p-1 lg:p-2 rounded-full bg-[#f42a37] text-white">
                3
              </div>
              <span className="text-darkText text-xs lg:text-sm">
                Na seção de PIX, selecione{" "}
                <span className="font-bold">"PIX Copia e Cola"</span>
              </span>
            </div>

            <div className="flex flex-row w-full h-fit gap-2 items-center justify-start">
              <div className="flex w-5 lg:w-7 h-5 lg:h-7 text-xs lg:text-sm items-center justify-center p-1 lg:p-2 rounded-full bg-[#f42a37] text-white">
                4
              </div>
              <span className="text-darkText text-xs lg:text-sm">
                Cole o código
              </span>
            </div>

            <div className="flex flex-row w-full h-fit gap-2 items-center justify-start">
              <div className="flex w-5 lg:w-7 h-5 lg:h-7 text-xs lg:text-sm items-center justify-center p-1 lg:p-2 rounded-full bg-[#f42a37] text-white">
                5
              </div>
              <span className="text-darkText text-xs lg:text-sm">
                Confirme o pagamento
              </span>
            </div>

            <div className="flex flex-row w-full h-fit gap-2 items-center justify-start">
              <div className="flex w-5 lg:w-7 h-5 lg:h-7 text-xs lg:text-sm items-center justify-center p-1 lg:p-2 rounded-full bg-[#f42a37] text-white">
                6
              </div>

              <span className="text-darkText text-xs lg:text-sm">
                Pronto, você está participando deste Jackpot!
              </span>
            </div>
          </div>

          <button
            className="w-full h-[55px] mt-3 text-white text-base lg:text-xl font-bold font-poppins uppercase bg-[#f42a37] hover:bg-green-700 rounded transition-all"
            onClick={() => {
              //navigate("/");
              setFormStep("");
              setIsOpen(false);
            }}
          >
            Continuar Comprando
          </button>
        </>
      ) : (
        <div role="status">
          <svg
            aria-hidden="true"
            className="inline w-7 h-7 mr-2 text-gray-200 animate-spin fill-action3"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
}
