import { WinnerToasterProps } from "@/@types/AppTypes";
import * as animationData from "../assets/Animation - 1704992648732.json";
import Lottie from "react-lottie";
import { MapPin, Trophy } from "@phosphor-icons/react";

export default function LastJackpotWinnerToaster({
  name,
  document,
  price,
  location,
  selected_color,
  tickets,
  dismiss,
}: WinnerToasterProps) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const isMultiple =
    Array.isArray(name) &&
    Array.isArray(selected_color) &&
    Array.isArray(document) &&
    Array.isArray(location) &&
    Array.isArray(price) &&
    Array.isArray(tickets);

  const renderWinnerElement = (index: number) => {
    const colorIndex = isMultiple ? selected_color[index] : selected_color;
    const colors = [
      "text-[#DDBE55]",
      "text-[#39B54A]",
      "text-[#0078E8]",
      "text-[#f42a37]",
    ];
    const winnerColor = colorIndex <= 3 ? colors[colorIndex] : "";
    return (
      <div
        key={index}
        className={`flex w-full items-center justify-between px-2 lg:px-[16px] py-1 lg:py-[8px] relative flex-1 grow rounded-[12px] border border-solid border-[#e6e6e6] mb-2`}
      >
        <div className="inline-flex items-center gap-[1px] lg:gap-[4px] relative flex-[0_0_auto]">
          <div className="relative w-fit font-medium text-[#454545] text-[9px] lg:text-[16px] tracking-[0] leading-[normal] whitespace-nowrap">
            {isMultiple ? name[index] : name}
          </div>
          <div className="inline-flex flex-col items-center justify-center gap-[8px] px-1 lg:px-[8px] py-[6px] relative flex-[0_0_auto] bg-white rounded-[500px]">
            <div className="relative w-fit mt-[-2.00px] [font-family:'Inter-Medium',Helvetica] font-medium text-[#006e2c] text-[9px] lg:text-[14px] text-center tracking-[0] leading-[normal]">
              {isMultiple ? document[index] : document}
            </div>
          </div>
          {colorIndex <= 3 ? (
            <div/>
          ) : (
            <div className="flex flex-row px-2 py-1 gap-1 border border-[#E4E4E4] rounded-full">
              <Trophy className="w-4 h-4 lg:w-5 lg:h-5 text-[#949494]" />
              <span className="uppercase text-[#949494] text-xs lg:text-sm">
                {colorIndex === 4
                  ? "hora"
                  : colorIndex === 5
                  ? "dia"
                  : colorIndex === 6
                  ? "semana"
                  : colorIndex === 7
                  ? "mes"
                  : "ano"}
              </span>
            </div>
          )}
        </div>
        <div className="inline-flex items-center gap-1 lg:gap-[10px] px-2 lg:px-[12px] py-[4px] relative flex-[0_0_auto] bg-[#f42a375d] rounded-full">
          <div className="relative w-fit mt-[-1.00px] font-medium text-[#f42a37] text-[9px] lg:text-[15px] tracking-[0] leading-[normal] whitespace-nowrap">
            +{" "}
            {new Intl.NumberFormat("pt-br", {
              style: "currency",
              currency: "BRL",
            }).format(Number(isMultiple ? price[index] : price))}
          </div>
        </div>
      </div>
    );
  };

  const renderTicketsInfo = () => {
    const durationTypes = ["HORA", "DIA", "SEMANA", "MES", "ANO"];
    if (!Array.isArray(selected_color)) return null;
    return selected_color.map((color, index) => {
      if (color > 3 && tickets[index]) {
        return (
          <span key={index} className="mt-2 text-2xl font-semibold text-[#3A3A3A]">
            Ticket sorteado Pix{durationTypes[color - 4]}: {tickets[index]}
          </span>
        );
      }
      return null;
    });
  };

  const renderWinnerElements = () => {
    if (isMultiple) {
      return name.map((_, index) => renderWinnerElement(index));
    } else {
      return renderWinnerElement(0);
    }
  };

  return (
    <div
      className={`flex flex-col w-[350px] lg:w-[568px] max-h-[70vh] lg:scrollbarLG items-center gap-1 lg:gap-[12px] px-3 lg:px-[15px] py-2 relative bg-hdgraybranco rounded-[16px] border-2 border-solid border-[#02a244] shadow-[0px_4px_27px_#00000040] overflow-y-auto`}
    >
      <div className="w-[120px] lg:w-[264px] h-[110px] lg:h-[182px]">
        <Lottie options={defaultOptions} />
      </div>
      <span className="text-2xl font-semibold text-[#3A3A3A]">
        Jackpot finalizado
      </span>
      <span className="text-[50px] font-bold text-[#f42a37]">
        {Array.isArray(name) && name.length > 1 ? "Vencedores" : "Vencedor"}
      </span>
      {renderTicketsInfo()}
      {renderWinnerElements()}
      <button
        className="px-6 py-2 border-2 border-[#f42a37] text-[#f42a37] text-[14px] font-medium rounded-lg"
        onClick={() => dismiss}
      >
        Fechar
      </button>
    </div>
  );
}
