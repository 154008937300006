import { ResultCardProps, ResultResponseProps } from "@/@types/AppTypes";
import {
  ArrowRight,
  CalendarBlank,
  CurrencyCircleDollar,
  Info,
  Star,
  Ticket,
  Trophy,
} from "@phosphor-icons/react";
import * as HoverCard from "@radix-ui/react-hover-card";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/pt-br";
import { useState } from "react";
import DisplayWinnersModal from "../components/modals/DisplayWinnersModal";

export default function ResultCard({
  id,
  date,
  type,
  price,
  selected_color,
  winner_ticket,
  winners,
}: ResultResponseProps) {
  const [displayWinnersModal, setDisplayWinnersModal] = useState(false);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(duration);
  const localEndTime = dayjs(date).tz(dayjs.tz.guess()).locale("pt-br");
  const formattedWinnerTicket = Number(winner_ticket).toLocaleString("en-US", {
    minimumIntegerDigits:
      type === "hour"
        ? 5
        : type === "day" || type === "week"
        ? 6
        : type === "month"
        ? 7
        : 8,
    useGrouping: false,
  });
  if (!winners) {
    return null;
  }

  return (
    <div className="relative flex flex-col items-center px-3 pb-4 py-7 gap-1 w-[280px] min-w-[280px] max-w-[300px] h-fit bg-white shadow-2xl rounded-2xl">
      <div className="absolute -top-5 left-[50%] translate-x-[-50%] flex whitespace-nowrap items-center justify-center py-2 px-3 gap-2 rounded-full bg-[#f42a37]">
        <Star className="w-[18px] h-[18px] text-white" weight="fill" />
        <span className="text-[14px] font-medium text-white">
          {type === "hour"
            ? "HORA"
            : type === "year"
            ? "ANO"
            : type === "month"
            ? "MÊS"
            : type === "week"
            ? "SEMANA"
            : "DIA"}
        </span>
      </div>

      <div className="flex  flex-col gap-1 items-center">
        <div className="flex flex-row items-center px-3 py-1 gap-2 rounded-full bg-[#5C5C5C] text-white text-[11px]  font-semibold">
          <Ticket className="w-5 h-5 text-white" weight="bold" />
          <span className="leading-[28px] text-center">
            {formattedWinnerTicket}
          </span>
        </div>
      </div>

      <p className="text-[#5C5C5C] text-[18px] leading-[23px]">
        Valor Sorteado
      </p>
      <div className="flex flex-row items-center justify-center gap-2">
        <p className="text-2xl text-[#f42a37] opacity-80">R$</p>
        <p className="text-3xl lg:text-5xl font-bold text-[#f42a37]">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL",
            currencyDisplay: "code",
          })
            .format(Number(price))
            .replace("BRL", "")
            .trim()}
        </p>
      </div>

      <div className="flex flex-row items-center justify-center gap-1 mt-2 mb-2">
        <CalendarBlank className="text-[#5C5C5C] w-5 h-5" />
        <p className="text-[#5C5C5C] leading-none text-[11px]">
          Data do Jackpot:{" "}
          {localEndTime.locale("pt-br").format("DD/MM/YYYY [às] HH:mm")}
        </p>
      </div>

      {winners && winners!.length == 1 ? (
        <div className="flex flex-row min-h-[54px]  w-full items-center justify-between gap-2 py-2 px-4 border border-[#D8D8D8] rounded-full">
          <p className="text-[#5C5C5C] text-[16px] tracking-wider font-medium">
            {winners![0].name}
          </p>

          <p className="text-[#f42a37] text-[13px] tracking-tight">
            {winners![0].document}
          </p>
        </div>
      ) : (
        <button
          className="flex flex-row w-full min-h-[54px] items-center justify-between gap-2 py-2 pl-2 pr-4 border border-[#D8D8D8] rounded-full"
          onClick={() => setDisplayWinnersModal(true)}
        >
          <div className="flex flex-row h-9 items-center justify-center py-0 px-4 gap-1 bg-[#f42a37] rounded-full">
            <Trophy className="w-6 h-6 lg:w-6 lg:h-6 text-white" />
            <span className="text-white tracking-wider font-semibold">
              {winners?.length}
            </span>
          </div>

          <span className="text-[#5C5C5C] font-medium">Ver ganhadores</span>
          <ArrowRight className="w-4 h-4 text-[#5C5C5C]" weight="bold" />
        </button>
      )}
      <DisplayWinnersModal
        isOpen={displayWinnersModal}
        setIsOpen={setDisplayWinnersModal}
        winners={winners!}
        selected_color={selected_color}
        ticket_number={formattedWinnerTicket}
      />
    </div>
  );
}
