import {
  CreatePurchaseBody,
  GetJackpotsResponse,
  GetPurchaseStatusListResponse,
  GetPurchaseStatusResponse,
  GetResultsProps,
  GetResultsResponse,
  ParticipantProps,
  ProfileResponse,
  ResultResponseProps,
} from "@/@types/AppTypes";
import { api } from "../axios/api";

const createPurchase = async (data: CreatePurchaseBody) => {
  const response = await api.post("/purchase", data);

  return response.data;
};

const getTotp = async ({ queryKey }: any) => {
  const [_, currentTotp] = queryKey;
  const response = await api.get(`/login_from_sms/${currentTotp}`);
  console.log(response);
  return response.data;
};

const getResults = async ({ queryKey }: any) => {
  const [_, order_price, order_type] = queryKey;
  const response = await api.get<ResultResponseProps[]>(
    `/jackpots/results/payout/${order_price}/${order_type}`
  );

  return response.data;
};

const getJackpots = async () => {
  const response = await api.get<GetJackpotsResponse>("/jackpots");

  return response.data;
};

const getJackpotParticipants = async ({ queryKey }: any) => {
  const [_, jackpot_id] = queryKey;
  if (jackpot_id === undefined || jackpot_id === null) return null;
  const response = await api.get<ParticipantProps[]>(
    `/jackpot/${jackpot_id}/participants`
  );

  return response.data;
};

const getProfile = async ({ queryKey }: any) => {
  const [_, token] = queryKey;
  if (token === undefined || token === null) return null;
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await api.get<ProfileResponse>(`/profile`);

  return response.data;
};

const getPurchaseStatus = async ({ queryKey }: any) => {
  const [_, purchasesArray] = queryKey;
  let purchase_id = purchasesArray;
  if (Array.isArray(purchasesArray)) {
    purchase_id = purchasesArray[0];
  }
  if (purchase_id === undefined || purchase_id === null) return null;
  const response = await api.get<GetPurchaseStatusResponse>(
    `/status/${purchase_id}`
  );

  return response.data;
};

const getPurchaseStatusList = async ({ queryKey }: any) => {
  const [_, purchase_id] = queryKey;
  if (purchase_id === undefined || purchase_id === null) return null;
  const response = await api.get<GetPurchaseStatusListResponse>(
    `/status_list/${purchase_id}`
  );

  return response.data;
};

export {
  createPurchase,
  getJackpots,
  getProfile,
  getTotp,
  getResults,
  getPurchaseStatus,
  getPurchaseStatusList,
  getJackpotParticipants,
};
