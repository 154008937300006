export default function RegulamentoSection() {
  return (
    <section className="flex flex-col flex-1 w-full h-fit p-6 gap-8 bg-white rounded-2xl shadow-lg shadow-slate-200">
      <div className="flex flex-col w-full h-fit gap-2" id="responsabilidades">
        <span className="uppercase text-[#f42a37] font-medium text-[18px] leading-[51px] trackiing-[2px]">
          Responsabilidades
        </span>
        <div className="flex flex-col w-full gap-4 text-[#616161] font-normal text-sm">
          <p>
            1. O site Pix-Por-Hora gerencia os pagamentos e a realização dos
            jackpots a cada hora.
          </p>
          <p>
            2. A Pix-Por-Hora é responsável pela criação e administração dos
            jackpots, garantindo a entrega dos prêmios e a gestão dos
            pagamentos.
          </p>
          <p>
            3. Conteúdo inapropriado ou violação das regras resultará na remoção
            imediata do conteúdo e possível banimento do usuário.
          </p>
        </div>
      </div>

      <div className="flex flex-col w-full h-fit gap-2" id="cadastro">
        <span className="uppercase text-[#f42a37] font-medium text-[18px] leading-[51px] trackiing-[2px]">
          CADASTRO/USO DO SITE
        </span>
        <div className="flex flex-col w-full gap-4 text-[#616161] font-normal text-sm">
          <p>
            4. A participação é restrita a pessoas físicas maiores de 18 anos.
          </p>
          <p>
            5. Ao se inscrever, o usuário autoriza a divulgação de seu e-mail
            para fins de anúncio, caso seja o ganhador.
          </p>
          <p>
            5.1 O usuário deve preencher corretamente os dados solicitados -
            Número de celular válido, para caso seja vencedor seja possível
            fazer contato.
          </p>
          <p>
            5.2 Após preenchimento dos dados, o usuário deve verificar as
            informações e realizar o pagamento via QR CODE.
          </p>
          <p>
            6. O usuário concorda em receber publicidade de novos
            jackpots/rifas, podendo cancelar a qualquer momento.
          </p>
        </div>
      </div>

      <div className="flex flex-col w-full h-fit gap-2" id="jackpot">
        <span className="uppercase text-[#f42a37] font-medium text-[18px] leading-[51px] trackiing-[2px]">
          Jackpots
        </span>
        <div className="flex flex-col w-full gap-4 text-[#616161] font-normal text-sm">
          <p>
            7. Os jackpots acontecem a cada hora, e o usuário deve efetuar o
            pagamento até o horário estipulado para participar do jackpot
            daquela hora.
          </p>
          <p>
            8. As apostas são feitas exclusivamente através do site
            Pix-Por-Hora.
          </p>
          <p>
            10. Em caso de não recebimento da confirmação do pagamento, o
            usuário deve contatar imediatamente o suporte.
          </p>
          <p>
            10.1 Reclamações sobre bilhetes não efetivados têm um prazo de 48
            horas.
          </p>
          <p>
            11. Não será permitida a troca de titularidade das apostas, caso
            seja vencedor, o prêmio será pago na mesma conta que efetuou o
            pagamento para confirmar a participação.
          </p>
        </div>
      </div>

      <div className="flex flex-col w-full h-fit gap-2" id="premio">
        <span className="uppercase text-[#f42a37] font-medium text-[18px] leading-[51px] trackiing-[2px]">
          Do Prêmio
        </span>
        <div className="flex flex-col w-full gap-4 text-[#616161] font-normal text-sm">
          <p>
            12. O prêmio consiste no total acumulado dos pagamentos do dia,
            menos uma taxa de administração de 20%.
          </p>
          <p>12.1 O prêmio é transferido via PIX ao ganhador.</p>
          <p>12.2 Os prêmios são transferidos até às 20h do dia do jackpot.</p>
        </div>
      </div>

      <div className="flex flex-col w-full h-fit gap-2" id="vencedor">
        <span className="uppercase text-[#f42a37] font-medium text-[18px] leading-[51px] trackiing-[2px]">
          Vencedor do Jackpot
        </span>
        <div className="flex flex-col w-full gap-4 text-[#616161] font-normal text-sm">
          <p>13. O ganhador será notificado via mensagem de texto.</p>
          <p>14. Em caso de duplicidade, será realizado um novo jackpot.</p>
        </div>
      </div>
    </section>
  );
}
