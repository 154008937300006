import { UpperSectionProps } from "../@types/AppTypes";
import { useSelector } from "react-redux";
import { RootState } from "../core/store";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/pt-br";
import JackpotCard from "../components/jackpotCards/JackpotCard";
import TopMenu from "./TopMenu";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../lib/react-query/queryFunctions";
import { useState } from "react";
import { SignIn } from "@phosphor-icons/react";

export function padWithZeros(number: number, minLength: number) {
  const numberString = number.toString();
  if (numberString.length >= minLength) return numberString;
  return "0".repeat(minLength - numberString.length) + numberString;
}

export default function UpperSection({
  refetchJackpots,
  refetchResults,
  setOpenCheckoutModal,
  setOpenParticipantsModal,
}: UpperSectionProps) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(duration);
  const [openMyTicketsModal, setOpenMyTicketsModal] = useState(false);
  const jackpots = useSelector(
    (state: RootState) => state.jackpots.openJackpot
  );
  const jackpotParticipants = useSelector(
    (state: RootState) => state.jackpots.openJackpotParticipants
  );
  const authToken = localStorage.getItem("authToken");
  const { data: Profile, refetch: refetchProfile } = useQuery({
    queryKey: ["getProfile", authToken],
    queryFn: getProfile,
    enabled: authToken ? true : false,
  });

  return (
    <section className="flex flex-col w-full pb-10 items-center justify-center  bg-[url('/public/header_1024.png')] xl:bg-[url('/public/background.png')] bg-cover lg:scrollbarLG">
      {authToken && (
        <TopMenu
          profile={Profile}
          openMyTicketsModal={openMyTicketsModal}
          setOpenMyTicketsModal={setOpenMyTicketsModal}
        />
      )}
      <div className="flex flex-col lg:flex-row w-full items-center justify-center pt-4 lg:pt-10 lg:pb-20 gap-6 lg:gap-12">
        {jackpots != null && (
          <JackpotCard
            refetchJackpots={refetchJackpots}
            refetchResults={refetchResults}
            refetchProfile={refetchProfile}
            profile={Profile}
            jackpotArray={jackpots!}
            jackpotParticipants={jackpotParticipants}
            setOpenMyTicketsModal={setOpenMyTicketsModal}
            setOpenCheckoutModal={setOpenCheckoutModal}
            setOpenParticipantsModal={setOpenParticipantsModal}
          />
        )}
      </div>
    </section>
  );
}
