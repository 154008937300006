import { TimeBarCountdownProps } from "@/@types/AppTypes";

export default function JackpotBarCountdown({
  progress,
  color,
}: TimeBarCountdownProps) {
  return (
    <div className="relative h-[7px] rounded-xl bg-[#F2F2F2] w-full">
      <div
        role="progressbar"
        aria-label="Progresso de venda de tickets"
        aria-valuenow={progress}
        className={`h-[7px] rounded-xl ${
          color ? color : "bg-[#f42a37]"
        } transition-all duration-700`}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
}
